import React from 'react'
import Header from "./components/Header"
import Button from 'react-bootstrap/Button';
import seo from './helpers/seo'

class Home extends React.Component {

  componentDidMount() {
    seo({
      title: 'Welcome'
    });
  }

  
  render() {
    return (
      <React.Fragment>
        <Header />
        <div id="hero-container">
          <div id="hero-logo">
            <div>
              <img src="/images/the-olde-barber-logo-1200.png" alt="The Olde Barber Cannabis Co." />
              <div className='p-4'>
                <h2 className='text-light text-lowercase'>33 Silver St., Cobalt, Ontario</h2>
                <Button href="/shop" className="rs btn-lg mt-4 rs text-lowercase" variant="outline-light">shop now</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="background-video">
          <video autoPlay playsInline loop muted poster="/images/cannabis-poster.jpg">
            <source src="/videos/1283463994.mp4" type="video/mp4" />
          </video>
        </div>

      </React.Fragment>
    )
  }

}

export default Home