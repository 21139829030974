import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Container from 'react-bootstrap/Container'
import seo from './helpers/seo'

class Faq extends React.Component {

  componentDidMount() {
    seo({
      title: 'FAQs'
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Container className="content">
          <div className="faq">
            <h1 className="mb-5">frequently asked questions</h1>

            <h3 className="mt-5">Health</h3>

            <section className="mt-4">
              <h5 className="mt-4">How does cannabis affect the body?</h5>
              <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
              </p>
            </section>
            
            <section className="mt-4">
              <h5 className="mt-4">Is cannabis safe to consume?</h5>
              <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
              </p>
            </section>

            <section className="mt-4">
              <h5 className="mt-4">Where can I find more information?</h5>
              <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
              </p>
            </section>

          </div>


          <h3 className="mt-5 mb-2">Cannabanoids</h3>

          <section className="mt-4">
            <h5 className="mt-4">What is THC?</h5>
            <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
            </p>
          </section>

          <section className="mt-4">
            <h5 className="mt-4">What is CBD?</h5>
            <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
            </p>
          </section>

          <section className="mt-4">
            <h5 className="mt-4">What are some other cannabanoids?</h5>
            <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
            </p>
          </section>


          <h3 className="mt-5 mb-2">f</h3>

          <section className="mt-4">
            <h5 className="mt-4">Is cannabis safe to consume?</h5>
            <p>Hot box at 4:20 the fatty dank endo doobie in a cashed roachclip, Bogart. Extremely dope chronic eye drops in the basement with psychedellic nugs. Oh my gawd, they like totally know I'm high. The cannabis industry will gravity bong a cotton mouth pizza dipped in ranch. Crystalized buds from trimming tasty weed pens THC sativa euphoric resinated dome piece.
            </p>
          </section>


        </Container >
        <Footer />
      </React.Fragment>
    )
  }

}

export default Faq