import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Container from 'react-bootstrap/Container'
import seo from './helpers/seo'

class Shop extends React.Component {
  
  componentDidMount() {
    // Add Buddi script tag
    const script = document.createElement("script");
    script.src = "https://app.buddi.io/js/external-ropis.js";
    script.async = true;
    const container = document.getElementById("shopContainer")
    if (!!container) {
      container.appendChild(script);
    }

    // Hide Shop Button and Header
    const buttonGroup = document.getElementById("headerButtonGroup")
    if (!!buttonGroup) {
      buttonGroup.classList.add("isolated-cart");
    }

    // SEO
    seo({
      title: 'Shop'
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Container id="shopContainer"></Container>
        <Footer />
      </React.Fragment>
    )
  }

}

export default Shop