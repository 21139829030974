import React from 'react'
import { Container } from 'react-bootstrap'

class Footer extends React.Component {
  
  render () {
    return(
      <Container className="footer mt-5 py-5 fs-6">
        <p>We &hearts; Cannabis</p>  
        <p>All employees are <strong>Certified Experts</strong> committed to education and safety. </p>
        {/* <p>Free same-day delivery to Cobalt, Haileybury, Latchford, and New Liskeard.</p> */}
        
      </Container>
    )
  }

}

export default Footer