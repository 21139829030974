import React from 'react'
import Header from "./components/Header"
import seo from './helpers/seo'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import { CardGroup } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'

class CultureClub extends React.Component {

  componentDidMount() {
    seo({
      title: 'Culture Club'
    });
  }

  
  render() {
    return (
      <React.Fragment>
        <Header />
        <Container className="content text-center">
          <h1>Get up to 25% off your next purchase!</h1>
          
          <p>Sign up for The Olde Barber Culture Club and receive 10 points/visit.<br /> Redeemable on all cannabis and cannabis accessories.</p>

          {/* <hr style={{marginTop: "2rem", marginBottom: "2rem"}} /> */}
          

          <h2><span class="mt-3 badge bg-secondary">10 points/visit</span></h2><br />

          <CardGroup>
        
          <Card className="text-center">
              <Card.Body>
                <Card.Title>WELCOME</Card.Title>
                <Alert variant="success"> 
                  <Alert.Heading className="rs" style={{fontSize: "2rem"}}>5% off</Alert.Heading>
                </Alert>
              </Card.Body>
            </Card>

            <Card className="text-center">
              <Card.Body>
                <Card.Title>50 points</Card.Title>
                <Alert variant="success"> 
                  <Alert.Heading className="rs" style={{fontSize: "2rem"}}>10% off</Alert.Heading>
                </Alert>
              </Card.Body>
            </Card>

            <Card className="text-center">
              <Card.Body>
                <Card.Title>80 points</Card.Title>
                <Alert variant="success"> 
                  <Alert.Heading className="rs" style={{fontSize: "2rem"}}>15% off</Alert.Heading>
                </Alert>
              </Card.Body>
            </Card>

            <Card className="text-center">
              <Card.Body>
                <Card.Title>120 points</Card.Title>
                <Alert variant="success"> 
                  <Alert.Heading className="rs" style={{fontSize: "2rem"}}>25% off</Alert.Heading>
                </Alert>
              </Card.Body>
            </Card>

          </CardGroup>
          <br />

          <h2>55+ Seniors Discount - 10% off every day!</h2>
          <h2>Birthday Discount - 15% off on your big day!</h2>
          <p>&hellip; more perks coming soon!</p>

          <div className="row mt-4">
            <div className="col">
            <Card className="text-center">
              <Card.Body>
                <img width="200px" src="/images/wallet-qr.png" alt="Points Wallet QR Code" />
                <div>
                  <h5 className="mt-5 mb-1">View Your Wallet</h5>
                  Scan the QR code with your camera and tap the link. Follow the instructions to save to your home screen.
                </div>
              </Card.Body>
              <a style={{textDecoration: 'none'}} target="_blank" rel="noreferrer" href="https://lab.alpineiq.com/wallet/1801">
                <Card.Footer className="rs text-center fs-5">or click here!</Card.Footer>
              </a>
            </Card>
            </div>
            <div className="col">
            <Card className="text-center">
              <Card.Body>
                <img width="200px;" src="/images/signup-qr.png" alt="Sigup QR Code" />
                <div className="">
                <h5 className="mt-5 mb-1">Club Signup</h5>
                  Scan the QR code with your camera and tap the link tp sign up. Receive rewards and birthday discounts.
                </div>
              </Card.Body>
              <a style={{textDecoration: 'none'}} target="_blank" rel="noreferrer" href="https://lab.alpineiq.com/join/c/1801/1827">
                <Card.Footer className="rs text-center fs-5">or click here!</Card.Footer>
              </a>
            </Card>
            </div>
          </div>



        </Container>
      </React.Fragment>
    )
  }

}

export default CultureClub