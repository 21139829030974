import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <Container>
      <div id="NotFoundContainer" className="text-center">
        <img src="/images/leaf-multiple-colour.png" alt="Not Foundg" />
        <h1 className="text-center m-5">we're not seeing straight &hellip;</h1>
        <Button onClick={() => navigate('/shop')} variant="outline-dark" className="rs btn-lg">back to the shop</Button>
      </div>
      
      <div class="overlay"></div>

      <div id="NotFoundContainerOuter">


        <div class="container">
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
          <svg class="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            <polygon points="" fill="none" stroke="hsl(320,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
            <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
              <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
            </polygon>
          </svg>
        </div>
      </div>

    </Container>
  )
}

export default NotFound;