import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Container from 'react-bootstrap/Container'
import seo from './helpers/seo'

class Policies extends React.Component {

  componentDidMount() {
    seo({
      title: 'Policies'
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Container className="policies content">
          <div>

            <h1>Policies</h1>

            <section className="mt-5">
              <p>
                These policies and terms of use were last updated on June 29, 2022.
              </p>

            </section>

            {/* Education */}


            <h3 className="mt-5 mb-2">Education</h3>

            <section className="mt-4">
              <h5 className="mt-4">In-Store Education</h5>
              <p>
                We are commited to education and safety. We will always have cannabis education available to customers through our in-store tablet and knowledgeable employees.
              </p>
              <p><a target="_blank" rel="noreferrer" href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis.html">Learn more about cannabis from the government of Canada</a>.</p>

              {/* <h5 className="mt-4">Employee Education</h5>
              <p>
                The Olde Barber Cannabis Co. has been recognized through the CannSell Gold Status Award. This means that all of our employees are Certified Experts, and trained at an elite level through CannSell, the definitive cannabis retail education program in the Province of Ontario.
              </p> */}
            </section>


            {/* RETURNS */}


            <h3 className="mt-5 mb-2">Return Policy</h3>

            <section className="mt-4">
              <h5 className="mt-4">Cannabis Products</h5>
              <p>
                All our active cannabis products (flower, extracts, oils, capsules, sprays, pre-rolls, edibles, topicals, etc) are final sale.
              </p>

              <h5 className="mt-4">Non-Cannabis Products</h5>
              <p>
                Unopened and unused non-cannabis products can be returned for a refund within 14 days of receipt. Shipping fees are not refundable. Refunds will be processed to the original payment method.
              </p>

              <h5 className="mt-4">Discounted Products</h5>
              <p>
                Sale/discounted items are final sale.
              </p>

              <h5 className="mt-4">All that being said ...</h5>
              <p>
              We appreciate your business and will do everything in our power to make it right.
              </p>
            </section>

            {/* <h3 className="mt-5 mb-2">Delivery</h3> */}

            {/* <section className="mt-4">
              <h5>Free, Same-Day</h5>
              <p>
                We offer free same-day delivery to Latchford, Coleman, Cobalt, North Cobalt, Haileybury, and New Liskeard. We only deliver to customers at least 19 years of age. Valid ID is required at time of delivery. Valid forms of ID can be found on the <a href="https://www.agco.ca/photo-identification" target="_blank" rel="noreferrer">AGCO website</a>. Delivery cutoff is 4:20pm and our delivery window is between 6pm and 7pm.
              </p>
            </section> */}

            {/* Discounts */}


            <h3 className="mt-5 mb-2">Discounts</h3>

            <section className="mt-4">
              <h5>Stacking</h5>
              <p>
                We only allow one discount per order. We do not allow the stacking of multiple discounts. Choose wisely :)
              </p>
            </section>


            {/* General */}


            <h3 className="mt-5 mb-2">Privacy</h3>

            <section className="mt-4">
              <h5>What is your privacy policy?</h5>
              <p>Your privacy is important to us. We use Buddi for our online store platform. They are Canada's #1 cannabis click &amp; collect, delivery, and digital menu provider. Their privacy policy can be found <a target="_blank" rel="noreferrer" href="https://buddi.io/legal/">here</a>.</p>
            </section>

            {/* Questions */}


            <h3 className="mt-5 mb-2">Questions</h3>

            <section className="mt-4">
              <h5>Contact Us!</h5>
              <p>
                Email <a href="mailto:theoldebarber@gmail.com">theoldebarber@gmail.com</a> for any inquiries or stop by our retail location.
              </p>
            </section>

          </div>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }

}

export default Policies