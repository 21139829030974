import "./App.scss"
import ReactGA from 'react-ga'
import Home from "./Home"
import CultureClub from "./CultureClub"
import About from "./About"
import Shop from "./Shop"
import Faq from "./Faq"
import Policies from "./Policies"
import NotFound from "./NotFound"
import AgeVerification from "./components/AgeVerification/AgeVerification"
// import CartUpdater from "./components/CartUpdater/CartUpdater"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"


function App() {

  ReactGA.initialize('G-8Y97P3TLBQ')
  
  return (
    <>
    <AgeVerification />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/culture-club" element={<CultureClub />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <CartUpdater /> */}
    </Router>
    </>
  );
}

export default App
