import React from 'react'
// import ReactGA from 'react-ga'
// import Badge from 'react-bootstrap/Badge'

// import Button from 'react-bootstrap/Button'
// import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
// import { MinecartLoaded } from 'react-bootstrap-icons';

class Header extends React.Component {

  // FIXME: Make counts dynamic
  
  // getCartItemCount() {
  //   // Need to update:
  //   // .cart-item-count .badge
  //   // .mobile-cart-item-count
  // }

  isHomepage() {
    return window.location.pathname === "/" ? "top" : "none"
  }

  render() {

    // const pageViewTracking = (props) => {
    //   const pathname = props.match.path
    //   let pageView = ''
 
    //   if (pathname === '*') pageView = '/not-found'
    //   else pageView = pathname
    //   ReactGA.pageview(pageView)
    // }

    // pageViewTracking()
    
    return (
      <React.Fragment>
        <Navbar fixed={ this.isHomepage() } bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand className="rs" href="/">
              <img
                alt=""
                src="/images/cannabis-leaf.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              The Olde Barber
              <span className="fs-6"> Cannabis Co.</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto">
                <Nav.Link href="/shop" className="rs">Shop</Nav.Link>
                {/* <Nav.Link href="/shop#/cart" className="d-sm-inline-block d-lg-none">
                  Cart
                  <Badge pill className="mobile-cart-item-count mx-2" bg="light" text="dark">0</Badge>
                </Nav.Link> */}
                <Nav.Link href="/culture-club" className="rs">Culture Club</Nav.Link>

                <NavDropdown  variant="dark" className="bg-dark rs text-lowercase" title="More">
                  {/* <NavDropdown.Item href="/faq">FAQs</NavDropdown.Item> */}

                  <NavDropdown.Item href="/about">About</NavDropdown.Item>                  
                  <NavDropdown.Item href="/policies">Policies</NavDropdown.Item>
                  <NavDropdown.Divider />
                  {/* <NavDropdown.Item target="_blank" rel="noreferrer" href="https://forms.gle/R35v6B3PejjBL5P67">Budtender Application</NavDropdown.Item> */}
                  <h6 className="dropdown-header">Contact</h6>
                  <NavDropdown.Item href="mailto:theoldebarber@gmail.com">theoldebarber@gmail.com</NavDropdown.Item>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item href="/shop#/login">Sign In</NavDropdown.Item>
                  <NavDropdown.Item href="/shop#/register">Sign Up</NavDropdown.Item> */}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>

            {/* <ButtonGroup id="headerButtonGroup" className="d-flex d-none d-lg-block" aria-label="Shop and Cart">
              <Button href="/shop" className="rs btn-lg" variant="outline-light">shop now</Button>
              <Button href="/shop#/cart" className="rs btn-lg" variant="outline-light">
                <MinecartLoaded />
                <Badge pill className="cart-item-count" bg="light" text="dark">0</Badge>
              </Button>
            </ButtonGroup> */}

          </Container>
        </Navbar>
      </React.Fragment>
    )
  }

}

export default Header