import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

export default function AgeVerification() {
  const [show, setShow] = useState(!isVerified())

  function verify() {
    localStorage.setItem("verified", true)
    setShow(false)
  }

  function isVerified() {
    return !!localStorage.getItem("verified")
  }

  function redirectUser() {
    const url = Math.random() > 0.5 ? "https://www.youtube.com/watch?v=QH2-TGUlwu4" : "https://www.youtube.com/watch?v=fZu3P0OsQPk";
    window.location.href = url
    return true
  }

  // If the user is verified, don't show the modal
  if (isVerified()) { return false }

  // else render the modal
  return (
    <Modal animation={false} className="AgeVerification" show={show} fullscreen={true}>
      <Modal.Body className="d-flex h-100 text-center text-white">
        <Container className="d-flex h-100 p-3 mx-auto flex-column">
          <img src="/images/cannabis-leaf.svg" alt="The Olde Barber Cannabis Co." />
          <h1>We only sell to adults <br /> age 19 years or older</h1>
          <p><small className="text-muted">By clicking "Yes" you are confirming that you are the age of majority and consent to the cannabis laws in your province (19+, or 18+ in Alberta and Quebec).</small></p>
          <h2 className="mt-4">ARE YOU OVER 19 YEARS OF AGE?</h2>
          <div>
            <Button onClick={verify} className="mx-2 rs btn-lg mt-4 text-lowercase" variant="outline-light">Yes</Button>
            <Button onClick={redirectUser} className="mx-2 rs btn-lg mt-4 text-lowercase" variant="outline-light">No</Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}